<template>
  <div class="flex flex-col h-screen justify-between">
    <VitePwaManifest />
    <Alerts />
    <Loading :loading="store.loading > 0"/>
    <NuxtLayout>
      <NuxtPage
        class="mb-auto pb-12"
        :class="{
          'mt-[85px] lg:mt-[124px]' : store.site.topBarText,
          'mt-[57px] lg:mt-[96px]' : !store.site.topBarText 
        }"
      />
    </NuxtLayout>
  </div>
</template>

<script setup>
import { useStore } from '@/stores/main'
const store = useStore()

useHead({
  meta: [
    { hid: 'viewport', name: 'viewport', content: 'width=device-width,initial-scale=1.0,maximum-scale=1.0' }
  ]
})

</script>