import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false

// Regular
import {
  faHeart,
  faMessage
} from '@fortawesome/free-regular-svg-icons'
library.add(
  faHeart
)

// Brands
import { faInstagram, faSquareFacebook, faYoutube, faGoogle } from '@fortawesome/free-brands-svg-icons'
library.add(
  faInstagram,
  faSquareFacebook,
  faYoutube,
  faGoogle
)

// Solid
import {
  faUser,
  faCartShopping,
  faCartPlus,
  faMagnifyingGlass,
  faComments,
  faSpinner,
  faTrash,
  faCircleExclamation,
  faBars,
  faChevronDown,
  faXmark
} from '@fortawesome/free-solid-svg-icons'
library.add(
  faUser,
  faCartShopping,
  faMagnifyingGlass,
  faComments,
  faSpinner,
  faCartPlus,
  faTrash,
  faCircleExclamation,
  faBars,
  faChevronDown,
  faXmark
)

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('fa', FontAwesomeIcon)
})
