import { getAnalytics } from "firebase/analytics"
import { useFirestore } from 'vuefire'
import { getDoc, doc } from 'firebase/firestore'
import { useStore } from '@/stores/main'

export default defineNuxtPlugin(async nuxtApp => {
//     const config = useRuntimeConfig()

  const db = useFirestore()
  const store = useStore()

  let analytics;
  if (process.client) {
    const user = await getCurrentUser()
    if (user) {
      const docRef = doc(db, `users/${user.email}`)
      const documentSnapshot = await getDoc(docRef);
      store.user = documentSnapshot.data()
      store.isLogedIn = true
    }
  
    analytics = getAnalytics(nuxtApp.$firebaseApp)
  }

  const docRef = doc(db, `sites/profesionalcosmetics`)
  const documentSnapshot = await getDoc(docRef)
  store.site = documentSnapshot.data()

  return {
    provide: {
      auth: nuxtApp.$firebaseAuth,
      db,
      analytics
    }
  }
})