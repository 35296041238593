import { default as about_45us3yBXdPum89Meta } from "/Users/nikita/my/profesionalcosmetics/pages/about-us.vue?macro=true";
import { default as cartlT6w9qZf1rMeta } from "/Users/nikita/my/profesionalcosmetics/pages/cart.vue?macro=true";
import { default as contact_45usgLLhjFPghMMeta } from "/Users/nikita/my/profesionalcosmetics/pages/contact-us.vue?macro=true";
import { default as cooperationzKwWzz7ExKMeta } from "/Users/nikita/my/profesionalcosmetics/pages/cooperation.vue?macro=true";
import { default as forgot_45passwordjh1ZkUH1FRMeta } from "/Users/nikita/my/profesionalcosmetics/pages/forgot-password.vue?macro=true";
import { default as indexn8L9XkfPFBMeta } from "/Users/nikita/my/profesionalcosmetics/pages/index.vue?macro=true";
import { default as new_45passwordjBp7zFLWEuMeta } from "/Users/nikita/my/profesionalcosmetics/pages/new-password.vue?macro=true";
import { default as indexZtoTIRrsbfMeta } from "/Users/nikita/my/profesionalcosmetics/pages/profile/information/index.vue?macro=true";
import { default as _91id_93R5kAnynC2pMeta } from "/Users/nikita/my/profesionalcosmetics/pages/profile/orders/[id].vue?macro=true";
import { default as indexJO2fU6FH8tMeta } from "/Users/nikita/my/profesionalcosmetics/pages/profile/orders/index.vue?macro=true";
import { default as profileM2mLwx66uJMeta } from "/Users/nikita/my/profesionalcosmetics/pages/profile.vue?macro=true";
import { default as return_45goodsUAIbI7s2xtMeta } from "/Users/nikita/my/profesionalcosmetics/pages/return-goods.vue?macro=true";
import { default as salepaNrfzProkMeta } from "/Users/nikita/my/profesionalcosmetics/pages/sale.vue?macro=true";
import { default as _91_46_46_46slug_93kPt964MHBeMeta } from "/Users/nikita/my/profesionalcosmetics/pages/shop/[...slug].vue?macro=true";
import { default as indexWvLeL4SwX8Meta } from "/Users/nikita/my/profesionalcosmetics/pages/shop/index.vue?macro=true";
import { default as _91id_93OP6fs6eFTOMeta } from "/Users/nikita/my/profesionalcosmetics/pages/shop/product/[id].vue?macro=true";
import { default as sign_45inFR8MnJaNF0Meta } from "/Users/nikita/my/profesionalcosmetics/pages/sign-in.vue?macro=true";
import { default as sign_45upK6EnbQmDdmMeta } from "/Users/nikita/my/profesionalcosmetics/pages/sign-up.vue?macro=true";
export default [
  {
    name: about_45us3yBXdPum89Meta?.name ?? "about-us",
    path: about_45us3yBXdPum89Meta?.path ?? "/about-us",
    meta: about_45us3yBXdPum89Meta || {},
    alias: about_45us3yBXdPum89Meta?.alias || [],
    redirect: about_45us3yBXdPum89Meta?.redirect || undefined,
    component: () => import("/Users/nikita/my/profesionalcosmetics/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: cartlT6w9qZf1rMeta?.name ?? "cart",
    path: cartlT6w9qZf1rMeta?.path ?? "/cart",
    meta: cartlT6w9qZf1rMeta || {},
    alias: cartlT6w9qZf1rMeta?.alias || [],
    redirect: cartlT6w9qZf1rMeta?.redirect || undefined,
    component: () => import("/Users/nikita/my/profesionalcosmetics/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: contact_45usgLLhjFPghMMeta?.name ?? "contact-us",
    path: contact_45usgLLhjFPghMMeta?.path ?? "/contact-us",
    meta: contact_45usgLLhjFPghMMeta || {},
    alias: contact_45usgLLhjFPghMMeta?.alias || [],
    redirect: contact_45usgLLhjFPghMMeta?.redirect || undefined,
    component: () => import("/Users/nikita/my/profesionalcosmetics/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: cooperationzKwWzz7ExKMeta?.name ?? "cooperation",
    path: cooperationzKwWzz7ExKMeta?.path ?? "/cooperation",
    meta: cooperationzKwWzz7ExKMeta || {},
    alias: cooperationzKwWzz7ExKMeta?.alias || [],
    redirect: cooperationzKwWzz7ExKMeta?.redirect || undefined,
    component: () => import("/Users/nikita/my/profesionalcosmetics/pages/cooperation.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordjh1ZkUH1FRMeta?.name ?? "forgot-password",
    path: forgot_45passwordjh1ZkUH1FRMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordjh1ZkUH1FRMeta || {},
    alias: forgot_45passwordjh1ZkUH1FRMeta?.alias || [],
    redirect: forgot_45passwordjh1ZkUH1FRMeta?.redirect || undefined,
    component: () => import("/Users/nikita/my/profesionalcosmetics/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexn8L9XkfPFBMeta?.name ?? "index",
    path: indexn8L9XkfPFBMeta?.path ?? "/",
    meta: indexn8L9XkfPFBMeta || {},
    alias: indexn8L9XkfPFBMeta?.alias || [],
    redirect: indexn8L9XkfPFBMeta?.redirect || undefined,
    component: () => import("/Users/nikita/my/profesionalcosmetics/pages/index.vue").then(m => m.default || m)
  },
  {
    name: new_45passwordjBp7zFLWEuMeta?.name ?? "new-password",
    path: new_45passwordjBp7zFLWEuMeta?.path ?? "/new-password",
    meta: new_45passwordjBp7zFLWEuMeta || {},
    alias: new_45passwordjBp7zFLWEuMeta?.alias || [],
    redirect: new_45passwordjBp7zFLWEuMeta?.redirect || undefined,
    component: () => import("/Users/nikita/my/profesionalcosmetics/pages/new-password.vue").then(m => m.default || m)
  },
  {
    name: profileM2mLwx66uJMeta?.name ?? "profile",
    path: profileM2mLwx66uJMeta?.path ?? "/profile",
    children: [
  {
    name: indexZtoTIRrsbfMeta?.name ?? "profile-information",
    path: indexZtoTIRrsbfMeta?.path ?? "information",
    meta: indexZtoTIRrsbfMeta || {},
    alias: indexZtoTIRrsbfMeta?.alias || [],
    redirect: indexZtoTIRrsbfMeta?.redirect || undefined,
    component: () => import("/Users/nikita/my/profesionalcosmetics/pages/profile/information/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93R5kAnynC2pMeta?.name ?? "profile-orders-id",
    path: _91id_93R5kAnynC2pMeta?.path ?? "orders/:id()",
    meta: _91id_93R5kAnynC2pMeta || {},
    alias: _91id_93R5kAnynC2pMeta?.alias || [],
    redirect: _91id_93R5kAnynC2pMeta?.redirect || undefined,
    component: () => import("/Users/nikita/my/profesionalcosmetics/pages/profile/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexJO2fU6FH8tMeta?.name ?? "profile-orders",
    path: indexJO2fU6FH8tMeta?.path ?? "orders",
    meta: indexJO2fU6FH8tMeta || {},
    alias: indexJO2fU6FH8tMeta?.alias || [],
    redirect: indexJO2fU6FH8tMeta?.redirect || undefined,
    component: () => import("/Users/nikita/my/profesionalcosmetics/pages/profile/orders/index.vue").then(m => m.default || m)
  }
],
    meta: profileM2mLwx66uJMeta || {},
    alias: profileM2mLwx66uJMeta?.alias || [],
    redirect: profileM2mLwx66uJMeta?.redirect || undefined,
    component: () => import("/Users/nikita/my/profesionalcosmetics/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: return_45goodsUAIbI7s2xtMeta?.name ?? "return-goods",
    path: return_45goodsUAIbI7s2xtMeta?.path ?? "/return-goods",
    meta: return_45goodsUAIbI7s2xtMeta || {},
    alias: return_45goodsUAIbI7s2xtMeta?.alias || [],
    redirect: return_45goodsUAIbI7s2xtMeta?.redirect || undefined,
    component: () => import("/Users/nikita/my/profesionalcosmetics/pages/return-goods.vue").then(m => m.default || m)
  },
  {
    name: salepaNrfzProkMeta?.name ?? "sale",
    path: salepaNrfzProkMeta?.path ?? "/sale",
    meta: salepaNrfzProkMeta || {},
    alias: salepaNrfzProkMeta?.alias || [],
    redirect: salepaNrfzProkMeta?.redirect || undefined,
    component: () => import("/Users/nikita/my/profesionalcosmetics/pages/sale.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93kPt964MHBeMeta?.name ?? "shop-slug",
    path: _91_46_46_46slug_93kPt964MHBeMeta?.path ?? "/shop/:slug(.*)*",
    meta: _91_46_46_46slug_93kPt964MHBeMeta || {},
    alias: _91_46_46_46slug_93kPt964MHBeMeta?.alias || [],
    redirect: _91_46_46_46slug_93kPt964MHBeMeta?.redirect || undefined,
    component: () => import("/Users/nikita/my/profesionalcosmetics/pages/shop/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexWvLeL4SwX8Meta?.name ?? "shop",
    path: indexWvLeL4SwX8Meta?.path ?? "/shop",
    meta: indexWvLeL4SwX8Meta || {},
    alias: indexWvLeL4SwX8Meta?.alias || [],
    redirect: indexWvLeL4SwX8Meta?.redirect || undefined,
    component: () => import("/Users/nikita/my/profesionalcosmetics/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93OP6fs6eFTOMeta?.name ?? "shop-product-id",
    path: _91id_93OP6fs6eFTOMeta?.path ?? "/shop/product/:id()",
    meta: _91id_93OP6fs6eFTOMeta || {},
    alias: _91id_93OP6fs6eFTOMeta?.alias || [],
    redirect: _91id_93OP6fs6eFTOMeta?.redirect || undefined,
    component: () => import("/Users/nikita/my/profesionalcosmetics/pages/shop/product/[id].vue").then(m => m.default || m)
  },
  {
    name: sign_45inFR8MnJaNF0Meta?.name ?? "sign-in",
    path: sign_45inFR8MnJaNF0Meta?.path ?? "/sign-in",
    meta: sign_45inFR8MnJaNF0Meta || {},
    alias: sign_45inFR8MnJaNF0Meta?.alias || [],
    redirect: sign_45inFR8MnJaNF0Meta?.redirect || undefined,
    component: () => import("/Users/nikita/my/profesionalcosmetics/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: sign_45upK6EnbQmDdmMeta?.name ?? "sign-up",
    path: sign_45upK6EnbQmDdmMeta?.path ?? "/sign-up",
    meta: sign_45upK6EnbQmDdmMeta || {},
    alias: sign_45upK6EnbQmDdmMeta?.alias || [],
    redirect: sign_45upK6EnbQmDdmMeta?.redirect || undefined,
    component: () => import("/Users/nikita/my/profesionalcosmetics/pages/sign-up.vue").then(m => m.default || m)
  }
]