<template>
  <div class="alert-wrapper ">
    <div
      v-for="alert in store.alerts"
      :key="`${alert.type}-${alert.message}`"
      class="alert"
      :class="`alert-${alert.type}`"
    >
      <fa v-if="alert.type !== 'success'" class="icon text-2xl" icon="fa-solid fa-circle-exclamation" />
      <div class="content">
        {{ alert.message }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from '@/stores/main'

const store = useStore()

</script>

<style scoped lang="postcss">
.alert-wrapper {
  @apply z-50 transition-all;
  position: fixed;
  top: 2em;
  left: 50%;
  transform: translateX(-50%);
  max-width: 80vw;

  &:empty {
    display: none;
  }
  .alert {
    @apply bg-black text-white px-10 py-3;
    border-radius: 1.4rem;
    position: relative;
    margin-bottom: 0.7rem;
    .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0.4rem;
      font-size: 1.7rem;
      line-height: 1;
      font-family: 'picpix';
      font-style: normal;
      &:before {
        margin: 0;
      }
    }
    .content {
      line-height: 16px;
      text-align: center;
    }
  }
  .alert-error {
    .icon {
      @apply text-red-400;
    }
  }
  .alert-success {
    .icon {
      @apply text-green-300;
    }
  }
  .alert-warning {
    .icon {
      @apply text-yellow-600;
    }
  }
  .alert-notice {
    .icon {
      @apply text-blue-600;
    }
  }
}
</style>
