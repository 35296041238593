<template>
  <transition name="fade" mode="out-in">
    <div v-show="loading" class="z-20 flex justify-center items-center h-full w-full fixed top-0 left-0 bg-black/40">
      <fa
        icon="fa-solid fa-spinner"
        spin-pulse
        size="3x"
        :style="{ color }"
      />
    </div>
  </transition>
</template>

<script setup >
import { toRefs } from 'vue';

const props = defineProps({
  loading: {
    type: Boolean,
    default: true
  },
  color: {
    type: String,
    default: '#f5d3d2'
  }
})

const { color, loading } = toRefs(props)

</script>
