import { useStore } from '@/stores/main'

export default defineNuxtPlugin(async nuxtApp => {
  const store = useStore()
  const actions = {
    showAlert: (type, message, timeout) => {
      store.showAlert([type, message, timeout]);
    },
    showSuccess: (message) => {
      store.showAlert(['success', message]);
    },
    showError: (message) => {
      store.showAlert(['error', message]);
    },
    showWarning: (message) => {
      store.showAlert(['warning', message]);
    },
  }

  return {
    provide: {
      alerts: actions
    }
  }
})
