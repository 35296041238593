import payload_plugin_e0k4tW9bdI from "/Users/nikita/my/profesionalcosmetics/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.mjs";
import revive_payload_client_4sVQNw7RlN from "/Users/nikita/my/profesionalcosmetics/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/nikita/my/profesionalcosmetics/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/nikita/my/profesionalcosmetics/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_9gMxCtyFUG from "/Users/nikita/my/profesionalcosmetics/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/Users/nikita/my/profesionalcosmetics/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/Users/nikita/my/profesionalcosmetics/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/nikita/my/profesionalcosmetics/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/nikita/my/profesionalcosmetics/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import templates_pwa_client_1a32469c_uAMS4z8xxb from "/Users/nikita/my/profesionalcosmetics/.nuxt/templates.pwa.client.1a32469c.ts";
import plugin_client_mik7N71GmK from "/Users/nikita/my/profesionalcosmetics/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.mjs";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/Users/nikita/my/profesionalcosmetics/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/Users/nikita/my/profesionalcosmetics/.nuxt/vuefire-plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/Users/nikita/my/profesionalcosmetics/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/nikita/my/profesionalcosmetics/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_AUP22rrBAc from "/Users/nikita/my/profesionalcosmetics/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _1_firebase_HkmzQSG7Sj from "/Users/nikita/my/profesionalcosmetics/plugins/1.firebase.js";
import alerts_HamQQDe1jt from "/Users/nikita/my/profesionalcosmetics/plugins/alerts.js";
import fontawesome_klhsrycjcK from "/Users/nikita/my/profesionalcosmetics/plugins/fontawesome.js";
import lightBox_6ggC3wbvLB from "/Users/nikita/my/profesionalcosmetics/plugins/lightBox.js";
import vue_clickaway_WhOLapjf8r from "/Users/nikita/my/profesionalcosmetics/plugins/vue-clickaway.js";
import vueFinalModal_Zm5eeqFeob from "/Users/nikita/my/profesionalcosmetics/plugins/vueFinalModal.js";
export default [
  payload_plugin_e0k4tW9bdI,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_9gMxCtyFUG,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  templates_pwa_client_1a32469c_uAMS4z8xxb,
  plugin_client_mik7N71GmK,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_AUP22rrBAc,
  _1_firebase_HkmzQSG7Sj,
  alerts_HamQQDe1jt,
  fontawesome_klhsrycjcK,
  lightBox_6ggC3wbvLB,
  vue_clickaway_WhOLapjf8r,
  vueFinalModal_Zm5eeqFeob
]