import { defineStore } from 'pinia'
import { useFirestore, useCollection, useDocument } from 'vuefire'
import { collection, doc } from 'firebase/firestore'

export const useStore = defineStore('store', () => {
  const db = useFirestore()

  const loading = ref(0)
  const site = ref({})
  const alerts = ref({})
  const user = ref({})
  const isLogedIn = ref(false)
  
  const products = useCollection(collection(db, 'products'))
  // const site = useDocument(doc(db, `sites/profesionalcosmetics`))
  const saleProducts = computed(() => products.value.filter((product) => product.variants.some((item) => item.sale && item.sale > 0))) 

  const showAlert = async ([type, message, timeout]) => {
    const id = Math.random().toString(36).substring(7);
    addAlert({ type, message, id });
    setTimeout(() => {
      removeAlert(id);
    }, timeout || 3000);
  }
  const addAlert = ({ type, message, id }) => {
    alerts.value[id] = { type, message };
  }
  const removeAlert = (id) => {
    delete alerts.value[id];
  }

  return {
    loading,
    site,
    alerts,
    user,
    isLogedIn,
    products,
    saleProducts,
    showAlert,
    addAlert,
    removeAlert
  }
})